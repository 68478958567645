<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">MONTHLY EVALUATION EMPLOYEES</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md3>
          <v-select
            v-model="month_of_id"
            class="mx-2"
            dense
            outlined
            label="Month Of"
            :items="month_of_items"
            item-value="id"
            item-text="month_of"
            @change="selected_category"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3>
          <v-select
            v-model="category_id"
            class="mx-2"
            dense
            outlined
            label="Category"
            :items="category_items"
            item-value="id"
            item-text="category"
            @change="selected_category"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3 v-if="(employee_id===1 || employee_id===2)">
          <v-select
            v-model="employee_position_id"
            class="mx-2"
            dense
            outlined
            label="Position"
            :items="employee_position_items"
            item-value="id"
            item-text="position"
            @change="selected_data"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3 v-if="(employee_id===1 || employee_id===2)">
          <v-select
            v-model="selected_branch_id"
            class="mx-2"
            dense
            outlined
            label="Branch/Department"
            :items="employee_branch_items"
            item-value="id"
            item-text="branch_code"
            @change="selected_data"
          ></v-select>
        </v-flex>

        <v-flex xs12 md12 v-if="is_generated">
          <v-select
            class="mx-2"
            v-model="bank"
            dense
            outlined
            label="Bank"
            :items="bank_items"
            item-text="bank_type"
            item-value="bank_type"
            :rules="rules.combobox_rule"
            @change="selected_bank_data"
          ></v-select>
        </v-flex>
        <v-col cols="12" v-show="alert">
          <v-alert
            color="warning"
            text
            class="mb-0"
          >
            <div class="d-flex align-start">
              <v-icon color="warning">
                {{ icons.mdiAlertOutline }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{alert_message}}
                </p>
              </div>
            </div>
          </v-alert>
        </v-col>
        <v-col cols="12" md="12" v-if="(employee_id===1 || employee_id===2) && !is_generated">
          <v-btn
            class="w-full"
            color="success"
            @click="approved_data"
            v-if="!saving_data"
          >
            Approved
          </v-btn>
          <v-progress-circular
            :size=50
            :width="5"
            color="primary"
            indeterminate
            v-else
          ></v-progress-circular>
        </v-col>
        <v-col cols="12" md="12" v-if="(employee_id===1 || employee_id===2) && is_generated">
          <v-btn
            class="w-full"
            color="info"
            @click="print_data"
            v-if="!is_printing"
          >
            Print Data
          </v-btn>
          <v-progress-circular
            :size=50
            :width="5"
            color="primary"
            indeterminate
            v-else
          ></v-progress-circular>
        </v-col>
      </v-layout>
      <v-data-table dense
                    class="mt-3"
                    :headers="(employee_id===2||employee_id===1)?headers2:is_head?headers5:(position==='HUMAN RESOURCE'?headers4:headers)"
                    :items="final_personal_info"
                    disable-pagination
                    hide-default-footer
      >
        <!--        <template v-slot:top>-->
        <!--          <h3 class="mx-2" v-if="(employee_id!=2||employee_id!=1)">PERFECT POINTS: {{over_all_points}}-->
        <!--          </h3>-->
        <!--        </template>-->
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ (final_personal_info
              .map(function (x) {
              return x.id
              })
              .indexOf(item.id)+1) }}
            </td>
            <td v-if="(employee_id!=2 &&employee_id!=1 && !is_generated)">
              <div v-if="Object.keys(item.evaluation).length === 0">
                <v-icon
                  v-if="(is_head&&item.is_head)||(!is_head&&!item.is_head)"
                  color="info"
                  @click="change_stat_cash_advance(item,0)"
                >{{ icons.mdiBadgeAccountAlertOutline }}
                </v-icon>
              </div>
              <div v-else>
                {{item.evaluation_points+' points'}}
              </div>
            </td>
            <td v-if="employee_id===2||employee_id===1||is_head">
              <div v-if="!is_generated">
                <v-chip color="error" v-if="Object.keys(item.has_done_evaluate).length === 0">
                  NOT YET DONE
                </v-chip>
                <v-chip color="success" v-else>
                  DONE
                </v-chip>
              </div>
              <div>{{''}}</div>
            </td>
            <td v-if="!is_generated">
              {{ item.name}}
            </td>
            <td v-else>
              {{ item.employee.last_name+', '
              +item.employee.first_name
              +' '+item.employee.middle_name}}
            </td>
            <td>
              {{ item.branch.branch_code }}
            </td>
            <td>
              {{ item.employee_position.position }}
            </td>
            <td>
              {{ item.duration }}
            </td>
            <td
              v-if="(employee_id===2||employee_id===1||is_head)">
              {{formatPrice(item.employee_points) }}
            </td>
            <td
              v-if="(employee_id===2||employee_id===1||is_head)">
              {{item.employee_percentage+' %' }}
            </td>
            <td
              v-if="(employee_id===2||employee_id===1||is_head)">
              {{item.cfo_points>0?formatPrice(item.cfo_points):''}}
              <div
                v-if="(((employee_id===2||employee_id===1))||is_head) && !is_generated">
                <v-icon
                  v-if="(item.cfo_points===0&&is_head && is_same_branch_checker(item.branch_id))||employee_id===2||employee_id===1"
                  color="info"
                  @click="change_stat_cash_advance(item,1)"
                >{{ icons.mdiBadgeAccountAlertOutline }}
                </v-icon>
                <v-icon
                  v-if="(is_head && is_same_branch_checker(item.branch_id))||employee_id===2||employee_id===1"
                  color="success"
                  @click="view_stat_cash_advance(item,1)"
                >{{ icons.mdiEyeCircle }}
                </v-icon>
              </div>
            </td>
            <td
              v-if="(employee_id===2||employee_id===1||is_head)">
              {{item.cfo_percentage>0?item.cfo_percentage+' %' :''}}
            </td>
            <td
              v-if="(employee_id===2||employee_id===1||is_head)">
              {{item.total_percentage>0?item.total_percentage+' %':'' }}
            </td>
            <td
              v-if="(employee_id===2||employee_id===1||employee_id===1)">
              {{item.incentives>0?formatPrice(item.incentives):''}}
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-data-table dense
                    v-if="(employee_id===1 || employee_id===2)"
                    class="mt-3"
                    :headers="headers3"
                    :items="below_info"
                    disable-pagination
                    hide-default-footer
      >
        <template v-slot:top>
          <h3 class="mx-2">BELOW 6 months
          </h3>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ (below_info
              .map(function (x) {
              return x.id
              })
              .indexOf(item.id)+1) }}
            </td>
            <td v-if="!is_generated">
              {{ item.name}}
            </td>
            <td v-else>
              {{ item.employee.last_name+', '
              +item.employee.first_name
              +' '+item.employee.middle_name}}
            </td>
            <td>
              {{ item.branch.branch_code }}
            </td>
            <td>
              {{ item.employee_position.position }}
            </td>
            <td>
              {{ item.duration }}
            </td>
            <td v-if="!is_generated">
              <v-chip @click="change_incentives(item)">
                {{item.incentives >
                0?formatPrice(item.incentives)
                :'Click Me.'}}
              </v-chip>
            </td>
            <td v-else>
              {{formatPrice(item.incentives)}}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="is_change_salary" persistent max-width="60%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">PEER EVALUATION FORM</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-radio-group
              mandatory
              v-model="row1"
              row
            >
              Initiative
              <v-spacer></v-spacer>
              <v-radio
                label="1"
                value="1"
              ></v-radio>
              <v-radio
                label="2"
                value="2"
              ></v-radio>
              <v-radio
                label="3"
                value="3"
              ></v-radio>
              <v-radio
                label="4"
                value="4"
              ></v-radio>
              <v-radio
                label="5"
                value="5"
              ></v-radio>
            </v-radio-group>
            <v-radio-group
              mandatory
              v-model="row2"
              row
            >
              Punctuality
              <v-spacer></v-spacer>
              <v-radio
                label="1"
                value="1"
              ></v-radio>
              <v-radio
                label="2"
                value="2"
              ></v-radio>
              <v-radio
                label="3"
                value="3"
              ></v-radio>
              <v-radio
                label="4"
                value="4"
              ></v-radio>
              <v-radio
                label="5"
                value="5"
              ></v-radio>
            </v-radio-group>
            <v-radio-group
              mandatory
              v-model="row3"
              row
            >
              Collaborative
              <v-spacer></v-spacer>
              <v-radio
                label="1"
                value="1"
              ></v-radio>
              <v-radio
                label="2"
                value="2"
              ></v-radio>
              <v-radio
                label="3"
                value="3"
              ></v-radio>
              <v-radio
                label="4"
                value="4"
              ></v-radio>
              <v-radio
                label="5"
                value="5"
              ></v-radio>
            </v-radio-group>
            <v-radio-group
              mandatory
              v-model="row4"
              row
            >
              Professionalism
              <v-spacer></v-spacer>
              <v-radio
                label="1"
                value="1"
              ></v-radio>
              <v-radio
                label="2"
                value="2"
              ></v-radio>
              <v-radio
                label="3"
                value="3"
              ></v-radio>
              <v-radio
                label="4"
                value="4"
              ></v-radio>
              <v-radio
                label="5"
                value="5"
              ></v-radio>
            </v-radio-group>
            <v-radio-group
              mandatory
              v-model="row5"
              row
            >
              Interpersonal Communication
              <v-spacer></v-spacer>
              <v-radio
                label="1"
                value="1"
              ></v-radio>
              <v-radio
                label="2"
                value="2"
              ></v-radio>
              <v-radio
                label="3"
                value="3"
              ></v-radio>
              <v-radio
                label="4"
                value="4"
              ></v-radio>
              <v-radio
                label="5"
                value="5"
              ></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex xs12 md12>
            <v-textarea
              class="mx-2"
              v-model="comment"
              dense
              label="Comment"
              outlined
              :rules="rules.default_max_500_character_rule"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-btn class="mt-4 w-full" color="primary" @click="update_cash_advance_previlige"
               v-if="!saving"> Save
        </v-btn>
        <v-progress-circular
          :size=50
          :width="5"
          color="primary"
          indeterminate
          v-else
        ></v-progress-circular>
        <v-btn class="mt-4 w-full" color="error" @click="is_change_salary = false"> Close</v-btn>
      </v-card>
    </v-dialog>
    <v-dialog v-model="is_cfo_change_points" persistent max-width="80%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">PEER EVALUATION FORM</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="new_cfo_points"
              label="New CFO Points"
              dense
              outlined
              min="0"
              max="25"
              type="number"
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-btn class="mt-4 w-full" color="primary" @click="update_cash_advance_previlige"
               v-if="!saving"> Save
        </v-btn>
        <v-progress-circular
          :size=50
          :width="5"
          color="primary"
          indeterminate
          v-else
        ></v-progress-circular>
        <v-btn class="mt-4 w-full" color="error" @click="is_cfo_change_points = false"> Close
        </v-btn>
      </v-card>
    </v-dialog>
    <v-dialog v-model="is_change_incentives" persistent max-width="80%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">PEER EVALUATION FORM</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="new_incentives"
              label="New Incentives"
              dense
              outlined
              type="number"
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-btn class="mt-4 w-full" color="primary" @click="update_below_incentives"
               v-if="!saving"> Save
        </v-btn>
        <v-progress-circular
          :size=50
          :width="5"
          color="primary"
          indeterminate
          v-else
        ></v-progress-circular>
        <v-btn class="mt-4 w-full" color="error" @click="is_change_incentives = false"> Close
        </v-btn>
      </v-card>
    </v-dialog>
    <v-dialog v-model="is_view_points" persistent max-width="80%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">VIEW POINTS & COMMENTS</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-data-table dense
                      class="mt-3"
                      :headers="points_header"
                      :items="points_items"
                      disable-pagination
                      hide-default-footer
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>
                {{ item.particulars }}
              </td>
              <td>
                {{ item.value }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-toolbar dense>
          <v-toolbar-title><h4 class="font-weight-light">COMMENTS AREA</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-data-table dense
                      class="mt-3"
                      :items="comments_items"
                      disable-pagination
                      hide-default-footer
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>
                {{
                comments_items
                .map(function (x) {
                return x.comments
                })
                .indexOf(item.comments) + 1
                }}
              </td>
              <td>
                {{ item.comments }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-btn class="mt-4 w-full" color="error" @click="is_view_points = false"> Close
        </v-btn>
      </v-card>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountOutline, mdiAccountCashOutline
    , mdiInformationOutline
    , mdiBadgeAccountAlertOutline
    , mdiPrinter
    , mdiEyeCircle
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      alert: false,
      alert_message: '',

      row1: '5',
      row2: '5',
      row3: '5',
      row4: '5',
      row5: '5',
      row6: '5',
      row7: '5',
      row8: '5',
      row9: '5',
      row10: '5',
      row11: '5',
      row12: '5',
      new_cfo_points: '0',
      over_all_points: '0',
      over_all_points_raw: 0,
      is_view_points: false,
      is_change_salary: false,
      is_change_incentives: false,
      is_generated: false,
      is_cfo_change_points: false,
      saving_data: false,
      is_printing: false,
      saving: false,
      is_head: false,
      is_cfo: false,
      category_id: '',
      new_incentives: '0',
      employee_position_id: 0,
      month_of_id: '',
      comment: '',
      category_items: [],
      month_of_items: [],
      category_items2: [],
      selected_branch_id: '',
      employee_branch_items: [],
      comments_items: [],
      personal_info: [],
      final_personal_info: [],
      bank_info: [],
      employment_info: [],
      type_of_employee: [],
      employee_position_items: [],
      points_items: [],
      bank_items: [],
      all_items: [],
      below_info: [],
      sorted_by: 'Name',
      bank: '',
      status: 'All',
      duration: 'All',
      maximum_amount: '',
      eval_employee_id: '',

      points_header: [
        {text: 'Particulars', value: 'particulars', sortable: true},
        {text: 'Value', value: 'value', sortable: true},
      ],
      headers: [
        {text: 'No.', value: 'date_of_deposit', sortable: false},
        {text: 'Evaluate', value: 'last_name', sortable: false},
        {text: 'Name', value: 'last_name', sortable: false},
        {text: 'Branch/Department', value: 'last_name', sortable: false},
        {text: 'Designation', value: 'last_name', sortable: false},
        {text: 'Duration', value: 'last_name', sortable: false},
      ],
      headers4: [
        {text: 'No.', value: 'date_of_deposit', sortable: false},
        {text: 'Evaluate', value: 'last_name', sortable: false},
        {text: 'Status', value: 'last_name', sortable: false},
        {text: 'Name', value: 'last_name', sortable: false},
        {text: 'Branch/Department', value: 'last_name', sortable: false},
        {text: 'Designation', value: 'last_name', sortable: false},
        {text: 'Duration', value: 'last_name', sortable: false},
      ],
      headers3: [
        {text: 'No.', value: 'date_of_deposit', sortable: false},
        {text: 'Name', value: 'last_name', sortable: false},
        {text: 'Branch/Department', value: 'last_name', sortable: false},
        {text: 'Designation', value: 'last_name', sortable: false},
        {text: 'Duration', value: 'last_name', sortable: false},
        {text: 'Incentives', value: 'last_name', sortable: false},
      ],
      headers2: [
        {text: 'No.', value: 'date_of_deposit', sortable: false},
        {text: 'Status', value: 'last_name', sortable: false},
        {text: 'Name', value: 'name', sortable: true},
        {text: 'Branch/Department', value: 'branch.branch_code', sortable: true},
        {text: 'Designation', value: 'employee_position.position', sortable: true},
        {text: 'Duration', value: 'last_name', sortable: true},
        {text: 'Employee Points', value: 'employee_points', sortable: true},
        {text: 'Employee Percentage (60%)', value: 'employee_percentage', sortable: true},
        {text: 'CFO Points', value: 'cfo_points', sortable: true},
        {text: 'CFO Percentage (40%)', value: 'cfo_percentage', sortable: true},
        {text: 'Total Points (100%)', value: 'total_percentage', sortable: true},
        {text: 'Incentives', value: 'incentives', sortable: true},
      ],
      headers5: [
        {text: 'No.', value: 'date_of_deposit', sortable: false},
        {text: 'Evaluate', value: 'last_name', sortable: false},
        {text: 'Status', value: 'last_name', sortable: false},
        {text: 'Name', value: 'name', sortable: true},
        {text: 'Branch/Department', value: 'branch.branch_code', sortable: true},
        {text: 'Designation', value: 'employee_position.position', sortable: true},
        {text: 'Duration', value: 'last_name', sortable: true},
        {text: 'Employee Points', value: 'employee_points', sortable: true},
        {text: 'Employee Percentage (60%)', value: 'employee_percentage', sortable: true},
        {text: 'CFO Points', value: 'cfo_points', sortable: true},
        {text: 'CFO Percentage (40%)', value: 'cfo_percentage', sortable: true},
        {text: 'Total Points (100%)', value: 'total_percentage', sortable: true},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiBadgeAccountAlertOutline,
          mdiEyeCircle,
          mdiPrinter,
        },
        is_deleting: false,
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      if (!this.is_can_evaluate) {
        this.$router.push({path: '/error-404'})
      }
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['position', 'is_can_evaluate']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing']),
      ...mapGetters('authentication', ['position', 'id_no', 'employee_category_id', 'employee_id', 'employee_branch_id']),
      ...mapGetters('authentication', ['address', 'contact', 'name', 'department', 'position']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('employee_evaluation', ['evaluate_employee', 'list_of_employee_to_evaluate', 'list_of_employee_cannot_evaluate', 'evaluate_below_employee']),
      ...mapActions('employee', ['initialize_list_of_employee_for_evaulation', 'is_head_checker_employee']),
      ...mapActions('employee_evaluation_status', ['create_employee_is_done_evaluate']),
      ...mapActions('employee_evaluation_data', ['create_employee_evaluation_data']),
      is_same_branch_checker(value) {
        return (this.employee_branch_id === value)
      },
      is_head_checker(value) {
        this.is_head_checker_employee({
          employee_id: value
        })
          .then(response => {
            this.is_head = response.data;
          })

      },
      async initialize_data() {
        await this.is_head_checker(this.employee_id)
        await this.initialize_list_of_employee_for_evaulation()
          .then(response => {
            this.category_items2 = response.data[0].category
            this.month_of_items = response.data[0].months
          })
          .catch(error => {
            console.log(error)
          })
        if (this.employee_category_id === 1) {
          this.category_items = this.category_items2
        } else {
          this.category_items.push(this.category_items2[
            this.category_items2
              .map(function (x) {
                return x.id
              })
              .indexOf(this.employee_category_id)
            ])
        }
      },
      duration_counter(value) {
        var a = moment(this.month_of_items[this.month_of_items.map(function (x) {
          return x.id;
        }).indexOf(this.month_of_id)].month_of,'MMMM YYYY');
        var b = moment(value, 'YYYY-MM-DD');

        var years = a.diff(b, 'year');
        b.add(years, 'years');

        var months = a.diff(b, 'months');
        b.add(months, 'months');

        var days = a.diff(b, 'days');
        return years + ' years ' + months + ' months ' + days + ' days'
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      change_stat_cash_advance(value, is_cfo) {
        this.is_cfo = is_cfo
        this.eval_employee_id = value.id

        this.row1 = value.evaluation.length > 0 ? value.evaluation[0].q1 + '' : '5';
        this.row2 = value.evaluation.length > 0 ? value.evaluation[0].q2 + '' : '5';
        this.row3 = value.evaluation.length > 0 ? value.evaluation[0].q3 + '' : '5';
        this.row4 = value.evaluation.length > 0 ? value.evaluation[0].q4 + '' : '5';
        this.row5 = value.evaluation.length > 0 ? value.evaluation[0].q5 + '' : '5';
        if (this.employee_id === 2 || is_cfo) {
          this.new_cfo_points = value.evaluation.length > 0 ? value.evaluation[0].total + '' : '0';
          this.is_cfo_change_points = true
        } else {
          this.is_change_salary = true
        }
      },
      async view_stat_cash_advance(value) {
        this.points_items = []
        var q1 = 0;
        var q2 = 0;
        var q3 = 0;
        var q4 = 0;
        var q5 = 0;
        var dat = [];
        if (value.over_all_evaluation.length > 0) {
          await value.over_all_evaluation.forEach(function (item) {
            q1 += item.q1;
            q2 += item.q2;
            q3 += item.q3;
            q4 += item.q4;
            q5 += item.q5;

            if (item.comment != null) {
              dat.push({
                comments: item.comment,
              })
            }
          })
        }
        this.comments_items = dat

        this.points_items.push({
          particulars: 'Initiative', value: q1,
        })
        this.points_items.push({
          particulars: 'Punctuality', value: q2,
        })
        this.points_items.push({
          particulars: 'Collaborative', value: q3,
        })
        this.points_items.push({
          particulars: 'Professionalism', value: q4,
        })
        this.points_items.push({
          particulars: 'Interpersonal Communication', value: q5,
        })

        this.is_view_points = true
      },
      update_cash_advance_previlige() {
        this.saving = true
        var proceed = true;
        if (this.employee_id === 2 || this.is_cfo) {
          if (this.new_cfo_points > 25) {
            this.alert = true
            this.alert_message = 'Points not greater then 25'
            this.saving_data = false
            this.saving = false
            this.is_cfo_change_points = false
            proceed = false
          }
        }
        if (proceed) {
          this.alert = false
          this.alert_message = ''
          this.saving_data = false
          this.saving = false
          this.evaluate_employee({
            month_of_id: this.month_of_id,
            created_by: this.employee_id,
            employee_id: this.eval_employee_id,
            comment: this.comment.toUpperCase(),
            is_cfo: this.is_cfo ? 1 : 0,

            q1: this.employee_id === 2 || this.is_cfo ? 0 : this.row1,
            q2: this.employee_id === 2 || this.is_cfo ? 0 : this.row2,
            q3: this.employee_id === 2 || this.is_cfo ? 0 : this.row3,
            q4: this.employee_id === 2 || this.is_cfo ? 0 : this.row4,
            q5: this.employee_id === 2 || this.is_cfo ? 0 : this.row5,
            total: this.employee_id === 2 || this.is_cfo ? this.new_cfo_points : (parseFloat(this.row1) + parseFloat(this.row2) + parseFloat(this.row3) + parseFloat(this.row4) + parseFloat(this.row5)),
          }).then(() => {
            this.selected_data()
          })
            .catch(error => {
              console.log(error)
            })
        }
      },
      update_below_incentives() {
        this.saving = true
        this.evaluate_below_employee({
          month_of_id: this.month_of_id,
          created_by: this.employee_id,
          employee_id: this.eval_employee_id,
          total: this.new_incentives,
        }).then(() => {
          this.selected_data()
        })
          .catch(error => {
            console.log(error)
          })
      },
      selected_category() {
        if (this.category_items.length > 0) {
          var index = this.category_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.category_id)
          if (index >= 0) {
            this.employee_position_items = this.category_items[index].employee_position
            this.employee_position_items.splice(0, 0, {
              id: 0,
              position: 'All'
            })
            this.employee_branch_items = this.category_items[index].branch
            this.employee_branch_items.splice(0, 0, {
              id: 0,
              branch_code: 'All'
            })
            this.selected_data()
          }
        }
      },
      selected_bank_data() {
        var index = this.bank_items
          .map(function (x) {
            return x.bank_type
          })
          .indexOf(this.bank)
        if (index >= 0) {
          this.final_personal_info = this.bank_items[index].detail
          this.below_info = this.bank_items[index].below
        }
      },
      selected_data() {
        if (this.employee_id != 1 && this.employee_id != 2) {
          this.selected_branch_id = this.employee_branch_id
        }
        this.eval_employee_id = ''
        this.is_change_salary = false
        this.is_change_incentives = false
        this.is_cfo_change_points = false
        this.is_generated = false
        const data = new FormData()
        this.final_personal_info = []
        this.below_info = []
        data.append('month_of_id', this.month_of_id);
        data.append('employee_id', this.employee_id);
        data.append('is_head', this.is_head);
        data.append('created_by', this.employee_id);
        data.append('employee_position_id', this.employee_position_id);
        data.append('employee_branch_id', this.selected_branch_id);
        data.append('category_id', this.category_id);
        this.list_of_employee_cannot_evaluate(data)
          .then(response => {
            if (response.data[0].details === 'generated') {
            } else {
              var temp = []
              var a = moment(this.month_of_items[this.month_of_items.map(function (x) {
                return x.id;
              }).indexOf(this.month_of_id)].month_of,'MMMM YYYY');
              response.data[0].data.forEach(function (item) {
                var b = moment(item.date_of_employment, 'YYYY-MM-DD');

                var years = a.diff(b, 'year');
                b.add(years, 'years');

                var months = a.diff(b, 'months');
                b.add(months, 'months');

                var days = a.diff(b, 'days');

                temp.push({
                  id: item.id,
                  evaluation: item.evaluation,
                  has_done_evaluate: item.has_done_evaluate,
                  name: item.last_name + ','
                    + item.first_name + ' ' + item.middle_name,
                  branch: item.branch,
                  bank_type: item.bank_type_1,
                  bank_account_name: item.bank_account_1,
                  bank_account_no: item.bank_account_no_1,
                  employee_position: item.employee_position,
                  date_of_employment: item.date_of_employment,
                  branch_id: item.branch_id,
                  evaluation_points: 0,
                  employee_points: 0,
                  employee_percentage: 0,
                  cfo_points: 0,
                  cfo_percentage: 0,
                  total_percentage: 0,
                  incentives: item.cfo_evaluation.length > 0 ? item.cfo_evaluation[0].total : 0,
                  duration: years + ' years ' + months + ' months ' + days + ' days',
                })
              })
              this.below_info = temp
            }
          })
          .catch(error => {
            console.log(error)
          })
        this.list_of_employee_to_evaluate(data)
          .then(response => {
            if (response.data[0].details === 'generated') {
              this.is_generated = true
              this.bank_items = response.data[0].data
              this.over_all_points = this.formatPrice(response.data[0].all_data.length * 25)
            } else {
              this.personal_info = response.data[0].data
              if (this.personal_info.length > 0) {
                var can_final = true
                var temp = []
                var over_all_points_rawr = 0

                var ss_head = this.is_head;
                this.personal_info.forEach(function (item) {
                  over_all_points_rawr = item.over_all_evaluation.length * 25
                  var total = 0
                  item.over_all_evaluation.forEach(function (item) {
                    total += parseFloat(item.total)
                  })
                  var emp_per = item.over_all_evaluation.length > 0 ? (((total.toFixed(2)
                    / (over_all_points_rawr)) * 60).toFixed(2)) : 0;
                  var cfo_per = item.cfo_evaluation.length > 0 ? (((item.cfo_evaluation[0].total) / 25) * 40).toFixed(2) : 0;
                  var total_per = (parseFloat(emp_per) + parseFloat(cfo_per)).toFixed();
                  var ince = 0;
                  if (total_per >= 96 && total_per <= 100) {
                    ince = 5000;
                  } else if (total_per >= 91 && total_per <= 95) {
                    ince = 4000;
                  } else if (total_per >= 86 && total_per <= 90) {
                    ince = 3500;
                  } else if (total_per >= 81 && total_per <= 85) {
                    ince = 3000;
                  } else if (total_per >= 76 && total_per <= 80) {
                    ince = 2000;
                  } else if (total_per >= 71 && total_per <= 75) {
                    ince = 1500;
                  }
                  var a = moment();
                  var b = moment(item.date_of_employment, 'YYYY-MM-DD');

                  var years = a.diff(b, 'year');
                  b.add(years, 'years');

                  var months = a.diff(b, 'months');
                  b.add(months, 'months');

                  var days = a.diff(b, 'days');

                  temp.push({
                    id: item.id,
                    is_head: item.is_head,
                    evaluation: item.evaluation,
                    has_done_evaluate: item.has_done_evaluate,
                    name: item.last_name + ','
                      + item.first_name + ' ' + item.middle_name,
                    branch: item.branch,
                    bank_type: item.bank_type_1,
                    bank_account_name: item.bank_account_1,
                    bank_account_no: item.bank_account_no_1,
                    employee_position: item.employee_position,
                    date_of_employment: item.date_of_employment,
                    over_all_evaluation: item.over_all_evaluation,
                    branch_id: item.branch_id,
                    evaluation_points: item.evaluation.length > 0 ? item.evaluation[0].total : 0,
                    employee_points: item.over_all_evaluation.length > 0 ? total.toFixed(2) : 0,
                    employee_percentage: emp_per,
                    cfo_points: item.cfo_evaluation.length > 0 ? item.cfo_evaluation[0].total : 0,
                    cfo_percentage: cfo_per,
                    total_percentage: total_per,
                    incentives: ince,
                    duration: years + ' years ' + months + ' months ' + days + ' days',
                  })
                  if (ss_head) {
                    if ((Object.keys(item.evaluation).length === 0 && item.is_head) || (Object.keys(item.cfo_evaluation).length === 0 && !item.is_head)) {
                      can_final = false;
                    }
                  } else {
                    if (Object.keys(item.evaluation).length === 0 && item.created_by != 2) {
                      can_final = false;
                    }
                  }
                })
                this.over_all_points = this.formatPrice(over_all_points_rawr)
                if (can_final) {
                  this.create_employee_is_done_evaluate(data)
                }
                this.final_personal_info = temp
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
        this.saving = false
      },
      change_incentives(item) {
        if (!this.is_generated) {
          this.eval_employee_id = item.id
          this.is_change_incentives = true
        }
      },
      approved_data() {
        this.saving_data = true
        this.alert = false

        if (this.personal_info.length > 0) {
          var can_final = true
          // this.personal_info.forEach(function (item) {
          //   if (Object.keys(item.has_done_evaluate).length === 0) {
          //     can_final = false;
          //   }
          // })
          if (can_final) {
            const data = new FormData()
            data.append('month_of_id', this.month_of_id);
            data.append('category_id', this.category_id);
            data.append('evaluation_data', JSON.stringify(this.final_personal_info));
            data.append('evaluation_data_below', JSON.stringify(this.below_info));
            this.create_employee_evaluation_data(data)
              .then(response => {
                this.saving_data = false
              })
              .catch(error => {
                console.log(error)
              })
          } else {
            this.alert = true
            this.alert_message = 'Not all employee evaluated'
            this.saving_data = false
          }
        } else {
          this.alert = true
          this.alert_message = 'No Data'
          this.saving_data = false
        }
      },
      print_data() {
        this.is_printing = true
        this.alert = false

        var imgData = this.company_logo
        var payments_array = []
        var payments_array2 = []
        var widths = []
        var info_array = []
        var info_array2 = []
        widths = [30, 150, 100, 80, 80, 60]
        payments_array.push([
          {text: 'No.', alignment: 'left', style: 'main_info',},
          {text: 'Name', alignment: 'left', style: 'main_info'},
          {text: 'Branch/Department', alignment: 'left', style: 'main_info'},
          {text: 'Designation', alignment: 'left', style: 'main_info'},
          {text: 'Duration', alignment: 'left', style: 'main_info'},
          {text: 'Incentives', alignment: 'left', style: 'main_info'},
        ])
        payments_array2.push([
          {text: 'No.', alignment: 'left', style: 'main_info',},
          {text: 'Name', alignment: 'left', style: 'main_info'},
          {text: 'Branch/Department', alignment: 'left', style: 'main_info'},
          {text: 'Designation', alignment: 'left', style: 'main_info'},
          {text: 'Duration', alignment: 'left', style: 'main_info'},
          {text: 'Incentives', alignment: 'left', style: 'main_info'},
        ])
        var value = this.final_personal_info;
        var total_am = 0
        value.forEach(function (item) {
          total_am += parseFloat(item.incentives)
          payments_array.push([
            {
              text: (value
                .map(function (x) {
                  return x.id
                })
                .indexOf(item.id) + 1),
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.employee.last_name + ', ' +
                item.employee.first_name + ' ' + item.employee.middle_name,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.branch.branch_code,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.employee_position.position,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.duration,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: (item.incentives / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2'
            },
          ])
        })

        var value2 = this.below_info;
        value2.forEach(function (item) {
          total_am += parseFloat(item.incentives)
          payments_array2.push([
            {
              text: (value2
                .map(function (x) {
                  return x.id
                })
                .indexOf(item.id) + 1),
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.employee.last_name + ', ' +
                item.employee.first_name + ' ' + item.employee.middle_name,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.branch.branch_code,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.employee_position.position,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.duration,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: (item.incentives / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2'
            },
          ])
        })

        info_array = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Bank Type: ',
                  {
                    text: this.bank_items[this.bank_items.map(function (x) {
                      return x.bank_type;
                    }).indexOf(this.bank)].bank_type,
                    style: 'main_data',
                  },
                ],
              },
            ],
          }, {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Category: ',
                  {
                    text: this.category_items[this.category_items.map(function (x) {
                      return x.id;
                    }).indexOf(this.category_id)].category,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  {
                    text: 'Month Of: ' + this.month_of_items[this.month_of_items.map(function (x) {
                      return x.id;
                    }).indexOf(this.month_of_id)].month_of,
                    style: 'main_data',
                  },
                ],
              },

            ],
          }, {
            columns: [
              {
                style: 'main_info',
                text: [
                  {
                    text: 'Total Of: ' + (total_am / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    style: 'main_data',
                  },
                ],
              },

            ],
          },
        ]
        info_array2 = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'BELOW 6 MONTHS',
                ],
              },
            ],
          },
        ]

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'LABOTTEGA',
                    {text: this.address, style: 'subheader'},
                    {
                      text: this.contact,
                      style: 'subheader',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            '_______________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'EMPLOYEE INCENTIVES',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                        color: 'blue',
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
            },
            '_______________________________________________________________________________________________',
            {
              stack: info_array2,
            },
            {
              table: {
                widths: widths,
                body: payments_array2,
                style: {fontSize: 1},
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                    },
                    {
                      text: '',
                    },
                  ],
                },
              ],
            },
          ],
          footer: {
            // columns: [
            //     {
            //         text: 'Generated with LABOTTEGA System',
            //         alignment: 'center',
            //         style: 'tableExample'
            //     }
            // ]
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 11,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.is_printing = false
      }
    },
  }
</script>
